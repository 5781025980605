<template>
    <router-view></router-view>
</template>
<script>
export default {
    data() {
        return {

        };
    },
    created() {},
    mounted() {}
};
</script>

<style scoped lang="scss">

</style>