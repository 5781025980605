import { render, staticRenderFns } from "./systemsetup.vue?vue&type=template&id=4c9ea546&scoped=true"
import script from "./systemsetup.vue?vue&type=script&lang=js"
export * from "./systemsetup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c9ea546",
  null
  
)

export default component.exports